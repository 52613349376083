import React from 'react';
import { forwardRef } from 'react';

import { useClientDataContext } from '@shared/client_data_context';
import { ServiceSelection } from '@shared/service_selector/constants';

import BADGE_ONE_STORAG_SRC from '@images/home/free_pickup_badge.svg';
import BADGE_TWO_MOVING_SRC from '@images/home/free_storage_badge.svg';
import BADGE_ONE_MOVING_SRC from '@images/home/starting_at_badge.svg';
import BADGE_TWO_STORAGE_SRC from '@images/home/store_longer_badge.svg';

type Badge = {
  src: string;
  alt: string;
};

const BADGE_ONE_STORAGE: Badge = {
  src: BADGE_ONE_STORAG_SRC,
  alt: 'Free pickup and delivery with long-term storage.',
};

const BADGE_TWO_STORAGE: Badge = {
  src: BADGE_TWO_STORAGE_SRC,
  alt: 'Staying longer? Save up to 55% on monthly storage!',
};

const BADGE_ONE_MOVING: Badge = {
  src: BADGE_ONE_MOVING_SRC,
  alt: 'Starting at $96 for small home moves.',
};

const BADGE_TWO_MOVING: Badge = {
  src: BADGE_TWO_MOVING_SRC,
  alt: 'Free Storage! First month free with moving.',
};

const useBadgeOne = () => {
  const {
    data: { serviceSelection },
  } = useClientDataContext();

  return serviceSelection === ServiceSelection.Moving
    ? BADGE_ONE_MOVING
    : BADGE_ONE_STORAGE;
};

const useBadgeTwo = () => {
  const {
    data: { serviceSelection },
  } = useClientDataContext();

  return serviceSelection === ServiceSelection.Moving
    ? BADGE_TWO_MOVING
    : BADGE_TWO_STORAGE;
};

export const BadgeOne = forwardRef<HTMLImageElement>((props, ref) => {
  const { src, alt } = useBadgeOne();

  return <img ref={ref} src={src} alt={alt} {...props} />;
});

export const BadgeTwo = forwardRef<HTMLImageElement>((props, ref) => {
  const { src, alt } = useBadgeTwo();

  return <img ref={ref} src={src} alt={alt} {...props} />;
});
